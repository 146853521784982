import React from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  loading: false,
  token: null,
  alert: {
    active: false,
    content: "",
  },
  user: {},
}

function reducer(state, action) {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: action.value,
      }
    case "LOGIN":
      return {
        ...state,
        user: action.user,
        token: action.token,
      }
    case "LOGOUT":
      return {
        ...state,
        user: {},
        token: null,
      }
    case "ALERT":
      return {
        ...state,
        alert: {
          active: true,
          content: action.content,
        },
      }
    case "CLOSE_ALERT":
      return {
        ...state,
        alert: {
          ...state.alert,
          active: false,
        },
      }
    default:
      return state
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
